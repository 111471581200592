<template>
  <container class="my_schedulings">
    <v-row class="header-container">
      <v-col cols="12" md="4" lg="3">
        <v-card-title>
          {{ $capitalize($tc("model.my_schedulings_page_title")) }}
        </v-card-title>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn @click="getSchedulings()" icon>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <v-menu
          offset-x
          v-model="advanced_filter"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge :value="advanced_filtered" color="red" dot overlap>
              <v-btn v-bind="attrs" v-on="on" icon small>
                <v-icon>mdi-filter-menu-outline</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <v-form @submit.prevent="handleAdvanceFilter()">
            <v-card>
              <v-card-title>
                {{ $capitalize($tc("model.my_schedulings_advanced_filters_title")) }}
              </v-card-title>
              <v-card-text>
                <v-simple-table class="filter-table">
                  <tr>
                    <td class="label">
                      {{ $capitalize($tc("model.my_schedulings_lead")) }}
                    </td>
                    <td>
                      <v-autocomplete
                        v-model="advanced_filters.lead_number"
                        :items="leads"
                        item-text="identifier_code"
                        item-value="identifier_code"
                        clearable
                      ></v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      {{ $capitalize($tc("model.my_schedulings_participant")) }}
                    </td>
                    <td>
                      <v-text-field
                        v-model="advanced_filters.participant_name"
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="label">
                      {{ $capitalize($tc("model.my_schedulings_type")) }}
                    </td>
                    <td>
                      <v-select
                        v-model="advanced_filters.types"
                        multiple
                        :items="scheduling_types"
                        item-text="description"
                        item-value="id"
                        chips
                      ></v-select>
                    </td>
                  </tr>
                </v-simple-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="handleResetFilter" text>{{
                  $capitalize($tc("model.my_schedulings_button_clean"))
                }}</v-btn>
                <v-btn type="submit" color="primary">{{
                  $capitalize($tc("model.my_schedulings_buttons_search"))
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col :lg="9">
        <v-card>
          <v-data-table
            :hide-default-footer="true"
            :headers="headers"
            :items="items"
            :options.sync="options"
            :disable="loading"
            :loading="loading"
            :loading-text="$capitalize($tc('model.my_schedulings_loading'))"
          >
            <template v-slot:header.date>
              {{ $capitalize($tc("model.my_schedulings_date")) }}
            </template>

            <template v-slot:header.time>
              {{ $capitalize($tc("model.my_schedulings_time")) }}
            </template>

            <template v-slot:header.participant_name>
              {{ $capitalize($tc("model.my_schedulings_participant")) }}
            </template>

            <template v-slot:header.lead>
              {{ $capitalize($tc("model.my_schedulings_lead")) }}
            </template>

            <template v-slot:header.type>
              {{ $capitalize($tc("model.my_schedulings_type")) }}
            </template>

            <template v-slot:header.actions>
              {{ $capitalize($tc("model.my_schedulings_actions")) }}
            </template>

            <template v-slot:item.date="{ item }">
              <v-chip label value>{{
                $moment(item.date).format("DD/MM/YYYY")
              }}</v-chip>
            </template>

            <template v-slot:item.time="{ item }">
              <v-chip label value>{{ item.time }}</v-chip>
            </template>

            <template v-slot:item.lead_number="{ item }">{{
              item.lead_number
            }}</template>

            <template v-slot:item.type="{ item }">
              <v-icon small :color="item.type.color"
                >mdi-checkbox-blank-circle</v-icon
              >
              {{ item.type.description }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                icon
                :to="{
                  name: 'lead_detail_scheduling_list',
                  params: {
                    lead_number: item.lead_number
                  }
                }"
              >
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col :lg="3">
        <v-card>
          <v-date-picker
            v-model="datesFilter"
            @change="handleAdvanceFilter"
            :disabled="loading"
            full-width
            range
            :events="schedulingsDates"
            event-color="green lighten-1"
          >
            <template>
              <v-btn
                text
                small
                @click="handleResetCalendar"
                :disabled="loading"
              >
                <v-icon left>mdi-close</v-icon
                >{{ $capitalize($tc("model.my_schedulings_button_clean")) }}
              </v-btn>
            </template>
          </v-date-picker>
        </v-card>
      </v-col>
    </v-row>
  </container>
</template>

<script>
import { my_schedulings } from "../services/scheduling";
import { my_leads } from "../services/lead";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      items: [],
      headers: [
        { text: "Data", align: "start", sortable: false, value: "date" },
        { text: "Horário", value: "time", sortable: false },
        { text: "Participante", value: "participant_name" },
        { text: "Lead", value: "lead_number", sortable: false },
        {
          text: "Tipo",
          value: "type",
          // TODO: Corrigir ordenação da relação
          sortable: false
        },
        { text: "Acções", value: "actions", sortable: false }
      ],
      loading: false,
      advanced_filter: false,
      advanced_filters: {
        status: ["scheduled", "pending_report", "report_is_late"],
        types: []
      },
      datesFilter: [],
      advanced_filtered: false,
      filter: null,
      leads: [],
      options: {
        sortBy: [],
        sortDesc: []
      }
    };
  },
  methods: {
    ...mapActions("lead_statuses", ["loadStatuses"]),
    handleAdvanceFilter(value) {
      this.getSchedulings();
      this.advanced_filter = false;
    },
    handleResetFilter() {
      this.advanced_filters = {
        status: [],
        leads: null,
        participant_name: null,
        types: []
      };
      this.advanced_filter = false;
      this.getSchedulings();
    },
    handleResetCalendar() {
      this.datesFilter = [];
      this.getSchedulings();
    },
    async getSchedulings() {
      this.loading = true;
      this.items = [];

      const { sortBy, sortDesc } = this.options;
      const response = await my_schedulings({
        ...this.advanced_filters,
        orderBy: sortBy[0] ? sortBy[0] : "",
        sortDesc: sortDesc[0] ? true : false,
        dateRange: this.datesFilter
      });

      this.items = response;
      this.loading = false;
    },
    async getMyLeads() {
      this.loading = true;
      this.leads = [];

      const response = await my_leads({
        except_statuses: this.lead_statuses
          .filter(status => status.name == "Concluído")
          .map(status => status.id)
      });

      this.leads = response.data;
      this.loading = false;
    },
    ...mapActions("scheduling_types", ["loadSchedulingTypes"])
  },
  watch: {
    options: {
      handler() {
        this.getSchedulings();
      },
      deep: true
    }
  },
  computed: {
    schedulingsDates: function() {
      return this.items.map(item => item.date);
    },
    ...mapState("lead_statuses", ["lead_statuses"]),
    ...mapState("scheduling_types", ["scheduling_types"])
  },
  created() {
    this.loadSchedulingTypes();
    this.getSchedulings();
    this.loadStatuses().finally(() => {
      this.getMyLeads();
    });
  }
};
</script>
